import BartendersAndCocktails from "assets/service-illustrations/bartenders_and_cocktails.svg?url";
import Beverages from "assets/service-illustrations/beverages.svg?url";
import Breakfast from "assets/service-illustrations/breakfast.svg?url";
import Brunch from "assets/service-illustrations/brunch.svg?url";
import CanteenService from "assets/service-illustrations/canteen_service.svg?url";
import CarpetCleaning from "assets/service-illustrations/carpet_cleaning.svg?url";
import Catering from "assets/service-illustrations/catering.svg?url";
import CateringForMeetings from "assets/service-illustrations/catering_for_meetings.svg?url";
import ChristmasDecorations from "assets/service-illustrations/christmas_decorations.svg?url";
import ChristmasGifts from "assets/service-illustrations/christmas_gifts.svg?url";
import ChristmasParty from "assets/service-illustrations/christmas_party.svg?url";
import ChristmasTrees from "assets/service-illustrations/christmas_trees.svg?url";
import CleaningSupplies from "assets/service-illustrations/cleaning_supplies.svg?url";
import CoffeeMachineInstallation from "assets/service-illustrations/coffee_machine_installation.svg?url";
import CoffeeSubscription from "assets/service-illustrations/coffee_subscription.svg?url";
import CollectionOfBulkyWaste from "assets/service-illustrations/collection_of_bulky_waste.svg?url";
import CorporateGifts from "assets/service-illustrations/corporate_gifts.svg?url";
import Decorations from "assets/service-illustrations/decorations.svg?url";
import DeepCleaning from "assets/service-illustrations/deep_cleaning.svg?url";
import Electrician from "assets/service-illustrations/electrician.svg?url";
import Entertainment from "assets/service-illustrations/entertainment.svg?url";
import EventPlanning from "assets/service-illustrations/event_planning.svg?url";
import EventStaff from "assets/service-illustrations/event_staff.svg?url";
import FloorPolish from "assets/service-illustrations/floor_polish.svg?url";
import FloorStripping from "assets/service-illustrations/floor_stripping.svg?url";
import FlowerSubscription from "assets/service-illustrations/flower_subscription.svg?url";
import Flowers from "assets/service-illustrations/flowers.svg?url";
import FruitSubscription from "assets/service-illustrations/fruit_subscription.svg?url";
import Handyman from "assets/service-illustrations/handyman.svg?url";
import LaundryService from "assets/service-illustrations/laundry_service.svg?url";
import Lunch from "assets/service-illustrations/lunch.svg?url";
import Merchandise from "assets/service-illustrations/merchandise.svg?url";
import Music from "assets/service-illustrations/music.svg?url";
import OfficeAssistant from "assets/service-illustrations/office_assistant.svg?url";
import OfficeCleaning from "assets/service-illustrations/office_cleaning.svg?url";
import Painter from "assets/service-illustrations/painter.svg?url";
import Pant from "assets/service-illustrations/pant.svg?url";
import PlantService from "assets/service-illustrations/plant_service.svg?url";
import PlantWallsAndMossWalls from "assets/service-illustrations/plant_walls_and_moss_walls.svg?url";
import Plants from "assets/service-illustrations/plants.svg?url";
import Plumbing from "assets/service-illustrations/plumbing.svg?url";
import PostConstructionCleaning from "assets/service-illustrations/post_construction_cleaning.svg?url";
import ScrubbingAndBuffingFloors from "assets/service-illustrations/scrubbing_and_buffing_floors.svg?url";
import Snacks from "assets/service-illustrations/snacks.svg?url";
import SummerParty from "assets/service-illustrations/summer_party.svg?url";
import TempStaffing from "assets/service-illustrations/temp_staffing.svg?url";
import WaitingStaff from "assets/service-illustrations/waiting_staff.svg?url";
import WindowPolishing from "assets/service-illustrations/window_polishing.svg?url";
import WasteSorting from "assets/service-illustrations/waste_sorting.svg?url";
import BodySDS from "assets/service-illustrations/body_sds.svg?url";
import FacilityManagement from "assets/service-illustrations/facility_management.svg?url";
import ExternalMovingService from "assets/service-illustrations/external_moving_service.svg?url";
import Ergonomics from "assets/service-illustrations/ergonomics.svg?url";
import ESGAndAntiGreenwashing from "assets/service-illustrations/esg_and_anti_greenwashing.svg?url";
import CurtainCleaning from "assets/service-illustrations/curtain_cleaning.svg?url";
import InternalRelocation from "assets/service-illustrations/internal_relocation.svg?url";
import ItSolutionsAndCyberSecurity from "assets/service-illustrations/it_solutions_and_cyber_security.svg?url";
import AccessSecurityAndSurveillance from "assets/service-illustrations/access_security_and_surveillance.svg?url";
import OfficeInteriorDesign from "assets/service-illustrations/office_interior_design.svg?url";
import Massage from "assets/service-illustrations/massage.svg?url";
import MatCleaning from "assets/service-illustrations/mat_cleaning.svg?url";
import MindfulnessCoaching from "assets/service-illustrations/mindfulness_coaching.svg?url";
import FurnitureCleaning from "assets/service-illustrations/furniture_cleaning.svg?url";
import PictureHanging from "assets/service-illustrations/picture_hanging.svg?url";
import CurtainInstallation from "assets/service-illustrations/curtain_installation.svg?url";
import ShelfInstallation from "assets/service-illustrations/shelf_installation.svg?url";
import TvMounting from "assets/service-illustrations/tv_mounting.svg?url";
import WhiteboardMounting from "assets/service-illustrations/whiteboard_mounting.svg?url";
import FloorSurfaceTreatment from "assets/service-illustrations/floor_surface_treatment.svg?url";
import DamageRestoration from "assets/service-illustrations/damage_restoration.svg?url";
import ScreenCleaning from "assets/service-illustrations/screen_cleaning.svg?url";
import SnowRemoval from "assets/service-illustrations/snow_removal.svg?url";
import HealthCheck from "assets/service-illustrations/health_check.svg?url";
import KeyboardCleaning from "assets/service-illustrations/keyboard_cleaning.svg?url";
import Teambuilding from "assets/service-illustrations/teambuilding.svg?url";
import Transport from "assets/service-illustrations/transport.svg?url";
import Yoga from "assets/service-illustrations/yoga.svg?url";

interface Category {
  colors: {
    light: string;
    dark: string;
  };
}

interface Categories {
  [key: number]: Category;
}

interface Service {
  illustration: any;
}

interface Services {
  [key: number]: Service;
}

const CATEGORIES: Categories = {
  1: {
    // cleaning
    colors: { light: "#e3ebfc", dark: "#6085ba" },
  },
  13: {
    // handyman_services
    colors: { light: "#ffedca", dark: "#f9ad32" },
  },
  14: {
    // food_and_drinks
    colors: { light: "#d7eed2", dark: "#5c8e4a" },
  },
  15: {
    // coffee
    colors: { light: "#ebd9c8", dark: "#a38263" },
  },
  16: {
    // fruit
    colors: { light: "#ffe1e0", dark: "#d56363" },
  },
  17: {
    // events_and_celebrations
    colors: { light: "#ccfaf8", dark: "#4aede8" },
  },
  18: {
    // office_and_wellness
    colors: { light: "#ffe6f1", dark: "#ce8aae" },
  },
};

const SERVICES: Services = {
  1: {
    // Erhvervsrengøring
    illustration: OfficeCleaning,
  },
  2: {
    // Vinduespudsning
    illustration: WindowPolishing,
  },
  3: {
    // Ejendomsservice
    illustration: FacilityManagement,
  },
  4: {
    // Piccolo- og piccolineservice
    illustration: OfficeAssistant,
  },
  5: {
    // Håndværkerrengøring
    illustration: PostConstructionCleaning,
  },
  6: {
    // Hovedrengøring
    illustration: DeepCleaning,
  },
  7: {
    // Handyman
    illustration: Handyman,
  },
  8: {
    // Rengøringsartikler
    illustration: CleaningSupplies,
  },
  9: {
    // Tæpperens
    illustration: CarpetCleaning,
  },
  10: {
    // Polishbehandling
    illustration: FloorPolish,
  },
  12: {
    // Gulvafhøvling
    illustration: FloorStripping,
  },
  13: {
    // Ekstern flytning
    illustration: ExternalMovingService,
  },
  14: {
    // Intern flytning
    illustration: InternalRelocation,
  },
  15: {
    // Maler
    illustration: Painter,
  },
  16: {
    // Planteservice
    illustration: PlantService,
  },
  17: {
    // Elektriker
    illustration: Electrician,
  },
  18: {
    // VVS
    illustration: Plumbing,
  },
  19: {
    // Snerydning
    illustration: SnowRemoval,
  },
  20: {
    // Firmajulegaver
    illustration: ChristmasGifts,
  },
  21: {
    // Event planlægning
    illustration: EventPlanning,
  },
  22: {
    // Tastaturrensning
    illustration: KeyboardCleaning,
  },
  23: {
    // Skærmrengøring
    illustration: ScreenCleaning,
  },
  24: {
    // Opsætning af whiteboard
    illustration: WhiteboardMounting,
  },
  25: {
    // Opsætning af TV
    illustration: TvMounting,
  },
  26: {
    // Opsætning af gardiner
    illustration: CurtainInstallation,
  },
  27: {
    // Opsætning af hylder
    illustration: ShelfInstallation,
  },
  28: {
    // Opsætning af billeder
    illustration: PictureHanging,
  },
  30: {
    // Afhentning af storskrald
    illustration: CollectionOfBulkyWaste,
  },
  31: {
    // Frokostordning
    illustration: Lunch,
  },
  33: {
    // Vikarservice
    illustration: TempStaffing,
  },
  35: {
    // Opskuring af gulve
    illustration: ScrubbingAndBuffingFloors,
  },
  36: {
    // Frugtordning
    illustration: FruitSubscription,
  },
  37: {
    // Overfladebehandling af gulv
    illustration: FloorSurfaceTreatment,
  },
  39: {
    // Massage
    illustration: Massage,
  },
  41: {
    // Vaskeservice
    illustration: LaundryService,
  },
  42: {
    // Adgangssikring og overvågning
    illustration: AccessSecurityAndSurveillance,
  },
  43: {
    // Kaffe abonnement
    illustration: CoffeeSubscription,
  },
  44: {
    // Kaffe maskininstallation
    illustration: CoffeeMachineInstallation,
  },
  46: {
    // Kontorindretning
    illustration: OfficeInteriorDesign,
  },
  47: {
    // Måtteservice
    illustration: MatCleaning,
  },
  48: {
    // Drikkevarer
    illustration: Beverages,
  },
  49: {
    // Snacks
    illustration: Snacks,
  },
  50: {
    // Mødeforplejning
    illustration: CateringForMeetings,
  },
  51: {
    // Firmagaver
    illustration: CorporateGifts,
  },
  52: {
    // Musik
    illustration: Music,
  },
  53: {
    // Bartendere og Cocktails
    illustration: BartendersAndCocktails,
  },
  54: {
    // Catering
    illustration: Catering,
  },
  55: {
    // Underholdning
    illustration: Entertainment,
  },
  56: {
    // Blomsterbuketter
    illustration: Flowers,
  },
  57: {
    // Blomsterabonnement
    illustration: FlowerSubscription,
  },
  58: {
    // Logo merchandise
    illustration: Merchandise,
  },
  59: {
    // Event personale
    illustration: EventStaff,
  },
  60: {
    // Frokostpersonale
    illustration: WaitingStaff,
  },
  61: {
    // Plantevægge og mosvægge
    illustration: PlantWallsAndMossWalls,
  },
  62: {
    // Dekorationer
    illustration: Decorations,
  },
  63: {
    // Julefrokost
    illustration: ChristmasParty,
  },
  64: {
    // IT-løsninger og sikkerhed
    illustration: ItSolutionsAndCyberSecurity,
  },
  65: {
    // Juletræer
    illustration: ChristmasTrees,
  },
  66: {
    // Brunch
    illustration: Brunch,
  },
  67: {
    // Kantineordning
    illustration: CanteenService,
  },
  68: {
    // Morgenmad
    illustration: Breakfast,
  },
  69: {
    // Affaldssortering
    illustration: WasteSorting,
  },
  70: {
    // BodySDS
    illustration: BodySDS,
  },
  71: {
    // Yoga
    illustration: Yoga,
  },
  72: {
    // Mindfulness coaching
    illustration: MindfulnessCoaching,
  },
  73: {
    // Gardinrens
    illustration: CurtainCleaning,
  },
  74: {
    // Pant
    illustration: Pant,
  },
  75: {
    // Møbelrens
    illustration: FurnitureCleaning,
  },
  76: {
    // Teambuilding
    illustration: Teambuilding,
  },
  77: {
    // Skadeservice
    illustration: DamageRestoration,
  },
  78: {
    // Sommerfest
    illustration: SummerParty,
  },
  79: {
    // Transport
    illustration: Transport,
  },
  80: {
    // Planter
    illustration: Plants,
  },
  81: {
    // Juledekorationer
    illustration: ChristmasDecorations,
  },
  82: {
    // Sundhedstjek
    illustration: HealthCheck,
  },
  83: {
    // Ergonomi
    illustration: Ergonomics,
  },
  84: {
    // ESG_OG_ANTI_GREENWASHING
    illustration: ESGAndAntiGreenwashing,
  },
  9000: {
    // Attrappe service
    illustration: TempStaffing,
  },
};

const SERVICE_PLAIN_TEXT_IDS = {
  RENGOERING: 1,
  ERHVERVSRENGOERING: 1,
  VINDUESPUDSNING: 2,
  VINDUESPOLERING: 2,
  EJENDOMSSERVICE: 3,
  PICCOLO__OG_PICCOLINESERVICE: 4,
  HAANDVAERKERRENGOERING: 5,
  HOVEDRENGOERING: 6,
  HANDYMAN: 7,
  RENGOERINGSARTIKLER: 8,
  TAEPPERENS: 9,
  POLISHBEHANDLING: 10,
  OLIERING_AF_GULVE: 11,
  GULVAFHOEVLING: 12,
  EKSTERN_FLYTNING: 13,
  INTERN_FLYTNING: 14,
  MALER: 15,
  PLANTESERVICE: 16,
  ELEKTRIKER: 17,
  VVS: 18,
  SNERYDNING: 19,
  FIRMAJULEGAVER: 20,
  EVENT_PLANLAEGNING: 21,
  TASTATURRENSNING: 22,
  SKAERMRENGOERING: 23,
  OPSAETNING_AF_WHITEBOARD: 24,
  OPSAETNING_AF_TV: 25,
  OPSAETNING_AF_GARDINER: 26,
  OPSAETNING_AF_HYLDER: 27,
  OPSAETNING_AF_BILLEDER: 28,
  INDKOEB_OG_SKIFT_AF_LYSKILDER: 29,
  AFHENTNING_AF_STORSKRALD: 30,
  FROKOSTORDNING: 31,
  KAFFESERVICE: 32,
  VIKARSERVICE: 33,
  MURER: 34,
  OPSKURING_AF_GULVE: 35,
  FRUGTORDNING: 36,
  OVERFLADEBEHANDLING_AF_GULV: 37,
  GLARMESTER: 38,
  MASSAGE: 39,
  TOEMRER: 40,
  VASKESERVICE: 41,
  ADGANGSSIKRING_OG_OVERVAAGNING: 42,
  KAFFE_ABONNEMENT: 43,
  KAFFE_MASKININSTALLATION: 44,
  TRYKSAGER: 45,
  KONTORINDRETNING: 46,
  MAATTESERVICE: 47,
  DRIKKEVARER: 48,
  SNACKS: 49,
  MOEDEFORPLEJNING: 50,
  FIRMAGAVER: 51,
  MUSIK: 52,
  BARTENDERE_OG_COCKTAILS: 53,
  CATERING: 54,
  MIDDAG: 54,
  UNDERHOLDNING: 55,
  BLOMSTERBUKETTER: 56,
  BLOMSTERBUKETTER_OG_PLANTER: 56,
  BLOMSTERABONNEMENT: 57,
  LOGO_MERCHANDISE: 58,
  EVENT_PERSONALE: 59,
  FROKOSTPERSONALE: 60,
  PLANTEVAEGGE_OG_MOSVAEGGE: 61,
  DEKORATIONER: 62,
  JULEFROKOST: 63,
  IT_LOESNINGER_OG_SIKKERHED: 64,
  JULETRAEER: 65,
  JULETRAEER_OG_DEKORATIONER: 65,
  BRUNCH: 66,
  KANTINEORDNING: 67,
  MORGENMAD: 68,
  AFFALDSSORTERING: 69,
  BODYSDS: 70,
  YOGA: 71,
  MINDFULNESS_COACHING: 72,
  GARDINRENS: 73,
  PANT: 74,
  MOEBELRENS: 75,
  TEAM_BUILDING: 76,
  SKADESERVICE: 77,
  SOMMERFEST: 78,
  TRANSPORT: 79,
  PLANTER: 80,
  JULEDEKORATIONER: 81,
  SUNDHEDSTJEK: 82,
  ERGONOMI: 83,
  ESG_OG_ANTI_GREENWASHING: 84,
  DUMMY_SERVICE: 9000,
};

export { CATEGORIES, SERVICES, SERVICE_PLAIN_TEXT_IDS };
